<template>
  <div id="in-review">
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/in-review/icon1.png"
        alt=""
    />
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/in-review/icon2.png"
        alt=""
    />
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/in-review/icon3.png"
        alt=""
    />
  </div>
</template>

<script>
export default {
  created() {
    localStorage.setItem('inReview', '1')
  }
}
</script>

<style lang="less" scoped>
#in-review {
  min-height: 100vh;
  padding: 1.12rem 0.533rem 0;
  background: url("https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/in-review/background.png");

  img:first-of-type {
    width: 5.6rem;
    margin: 0 auto;
  }

  img:nth-of-type(2) {
    margin: 0.427rem auto 0.693rem;
  }
}
</style>